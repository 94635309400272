import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ability from "@/libs/acl/ability";

export default function useClientsList() {
  // Use toast
  const toast = useToast();

  const refClientListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "account_number", label: "JVSakk A/C No.", sortable: true },
    { key: "name_en", stickyColumn: true, label: "Client Name", sortable: true },
    { key: "account_type", label: "Account Type" },
    { key: "relationship_manager.name", label: "Relationship Manager" },
    { key: "custodian_bank", label: "Custodian Bank" },
    { key: "account_status", label: "Account Status" },
    { key: "actions" },
  ];
  const perPage = ref(10);
  const totalClients = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refClientListTable.value
      ? refClientListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalClients.value,
    };
  });

  const refetchData = () => {
    refClientListTable.value.refresh();
  };

  watch(
    [currentPage, perPage, searchQuery, statusFilter],
    () => {
      refetchData();
    }
  );

  const fetchUserClients = (ctx, callback) => {
    store
      .dispatch("app-client/fetchUserClients", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      })
      .then((response) => {
        console.log(response)
        const { clients, total } = response.data;
        callback(clients);
        totalClients.value = total;
      })
      .catch((err) => {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching client list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    fetchUserClients,
    tableColumns,
    perPage,
    currentPage,
    totalClients,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refClientListTable,
    ability,
    refetchData,
    statusFilter
  };
}
