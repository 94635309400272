<template>
    <div>
      <b-card no-body class="mb-0">
        <div class="m-2">
          <!-- Table Top -->
  
          <div class="d-flex flex-wrap flex-md-nowrap align-items-center">
            <h3 class="font-weight-bolder text-nowrap mb-0">CLIENT</h3>
            <div class="form-col-select ml-2">
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block"
              />
              <feather-icon size="18" icon="ChevronDownIcon" />
            </div>
            <div class="search-primary w-100">
              <feather-icon size="16" icon="SearchIcon" />
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Find Client"
              />
            </div>
          </div>
  
          <div class="d-flex mt-2 mt-sm-1 flex-wrap flex-sm-nowrap">
            <b-button
              class="mr-2 mt-1 mt-sm-0"
              variant="primary"
              @click="statusFilter = null"
            >
              <feather-icon icon="AlignJustifyIcon" class="mr-50" />
              All
            </b-button>
            <b-button
              class="mr-2 mt-1 mt-sm-0"
              variant="primary"
              @click="statusFilter = '1'"
            >
              <feather-icon icon="BookOpenIcon" class="mr-50" />
              Active
            </b-button>
            <b-button
              class="mr-2 mt-1 mt-sm-0"
              variant="primary"
              @click="statusFilter = '2'"
            >
              <feather-icon icon="LoaderIcon" class="mr-50" />
              Inactive
            </b-button>
            <b-button
              class="mr-2 mt-1 mt-sm-0"
              variant="primary"
              @click="statusFilter = '3'"
            >
              <feather-icon icon="CheckIcon" class="mr-50" />
              Suspended
            </b-button>
            <b-button class="mt-1 mt-sm-0" variant="primary" @click="statusFilter = '4'">
              <feather-icon icon="XIcon" class="mr-50" />
              Close
            </b-button>
          </div>
        </div>
  
        <b-table
          ref="refClientListTable"
          class="position-relative"
          :items="fetchUserClients"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(account_type)="data">
            <div
              v-for="(type, index) in data.item.account_types"
              :key="`account_type_${index}`"
            >
              {{ type.name }}
            </div>
          </template>
  
          <template #cell(custodian_bank)="data">
            <div v-for="(bank, index) in data.item.banks" :key="`bank_${index}`">
              {{ bank.bank && bank.bank.name }}
            </div>
          </template>
  
          <template #cell(account_status)="data">
            <span
              :class="{
                'text-succss': data.item.account_status.active_bit,
                'text-danger': !data.item.account_status.active_bit,
              }"
            >
              {{ data.item.account_status.status_name }}</span
            >
          </template>
  
          <template #cell(account_number)="data">
            <div v-if="data.item.is_approved == 0">
              <span class="text-warning">{{ data.item.account_number }}</span>
            </div>
            <div v-else>{{ data.item.account_number }}</div>
          </template>
  
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown class="action-trigger-btn" :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="EyeIcon" size="16" class="align-middle text-body" />
              </template>
              <b-dropdown-item
                :to="{ name: 'account-detail', params: { id: data.item.id } }"
              >
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                {{ dataMeta.of }} entries</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalClients"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </template>
  
  <script>
  import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
  } from "bootstrap-vue";
  import vSelect from "vue-select";
  import store from "@/store";
  import { ref, onUnmounted } from "@vue/composition-api";
  import useClientUserList from "./useClientUserList";
  import clientUserStoreModule from "../clientUserStoreModule";
  
  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      vSelect,
    },
    methods: {
    },
    data() {
      return {

      };
    },
    setup() {
      const USER_APP_STORE_MODULE_NAME = "app-client";
  
      // Register module
      if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.registerModule(USER_APP_STORE_MODULE_NAME, clientUserStoreModule);
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME))
          store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      });
  
      const {
        fetchUserClients,
        tableColumns,
        perPage,
        currentPage,
        totalClients,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refClientListTable,
        refetchData,
        ability,
        statusFilter,
      } = useClientUserList();
  
      return {
        // Sidebar
        fetchUserClients,
        tableColumns,
        perPage,
        currentPage,
        totalClients,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refClientListTable,
        refetchData,
        ability,
        statusFilter,
      };
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  </style>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
  